
html,
body,
body > div { /* the react root */
    margin: 0;
    padding: 0;
    height: 100%;
}

h2 {
    margin: 0;
    /*font-size: 16px;*/
}

ul {
    margin: 0;
    padding: 0 0 0 1.5em;
}

li {
    margin: 1.5em 0;
    padding: 0;
}

b { /* used for event dates/times */
    margin-right: 3px;
}

.fc-button-primary {
    /*background-color: #198754 !important;*/
    /*border-color: #198754 !important;*/
    /*background-color: #028538 !important;*/
    /*border-color: #028538 !important;*/
}
.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
    /*background-color: #13653f !important;*/
    /*border-color: #13653f !important;*/
    /*background-color: #02702f !important;*/
    /*border-color: #02702f !important;*/
}

.sticky-top {
    background-color: #fff;
    z-index: 999;
}

/* #198754 */
