/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');*/
/*@import url('https://fonts.googleapis.com/css?family=Roboto,Helvetica');*/

/*.MuiTableCell-body {*/
/*    padding: 5px !important;*/
/*    font-size: 0.9rem !important;*/
/*}*/

/*.MuiTableCell-head {*/
/*    padding: 5px !important;*/
/*    font-size: 0.9rem !important;*/
/*}*/
/*.MuiTableCell-root {*/
/*    padding: 5px 4px !important;*/
/*    font-size: 0.9rem !important;*/
/*    font-family: "Montserrat", sans-serif !important;*/
/*}*/

/*.MuiTable-root {*/
/*    background-color: #F1F1F1;*/
/*    font-family: "Montserrat", sans-serif;*/
/*}*/

/*.MuiFormLabel-root {*/
/*    font-family: "Montserrat", sans-serif !important;*/
/*}*/
/*.MuiSelect-select {*/
/*    font-family: "Montserrat", sans-serif !important;*/
/*}*/
/*.MuiTable-root caption {*/
/*    font-family: "Montserrat", sans-serif !important;*/
/*}*/
/*.MuiFormControl-root {*/
/*    font-family: "Montserrat", sans-serif !important;*/
/*}*/
/*.MuiButtonBase-root {*/
/*    font-family: "Montserrat", sans-serif !important;*/
/*}*/
/*.MuiBox-root .MuiTable-root {*/
/*    background: none;*/
/*}*/

/*.MuiCollapse-wrapper .MuiTableCell-body {*/
/*    !*padding: 6px 16px;*!*/
/*}*/

/*.MuiTypography-root {*/
/*    font-size: 1.15rem !important;*/
/*    !*font-family: "Montserrat", sans-serif !important;*!*/
/*}*/
/*.MuiTableCell-head {*/
/*    font-weight: bold !important;*/
/*}*/


/*.collapse22 {*/
/*    padding: 0 !important;*/
/*    background-color: lightgray;*/
/*}*/

/*.finance {*/
/*    !*font-size: 1.2rem;*!*/
/*    !*font-weight: 600;*!*/
/*    font-family: 'Montserrat', sans-serif;*/
/*}*/
/*.container h5 {*/
/*    font-size: 1.2rem;*/
/*    font-weight: 600;*/
/*    !*font-family: 'Poppins', sans-serif;*!*/
/*}*/

.review_table .MuiButtonBase-root{
    padding: 0px;
}
.review_table .MuiTableCell-root{
    padding: 5px 3px !important
}
.review_table .collapse22{
    padding: 0px !important
}
