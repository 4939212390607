.reviews li {
    margin: 0
}

.MuiListSubheader-root {
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    font-family: 'Poppins', sans-serif !important;
    padding: 0 !important;
    line-height: 1.2;
    color: inherit !important;
    margin-top: 50px;
}

.container h5 {
    font-size: 1.2rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    line-height: inherit;
}
