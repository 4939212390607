/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/
/*@tailwind variants;*/

html, body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

#calendar {
  /*max-width: 1100px;*/
  margin: 0px auto;
}
.fc .fc-toolbar-title {
  font-size: 1.0em;
  margin: 0px;
}
.slots {
  display: inline;
}
.rectangle {
  /*width: 3%;*/
  width: 2.5%;
  border: 0px solid;
  margin: auto;
  background: #cccccc;
  height: 20px;
  display: inline-block;
  /*margin: 0 2px;*/
  margin: 0 1px;
  float: right;
}

.fc table {
  font-size: 0.9em;
}

.finish .fc-list-event-dot{
  border-color: #198754;
}
.in_progress .fc-list-event-dot{
  border-color: #ffc107;
}
.open .fc-list-event-dot{
  border-color: #dee2e6;
}
.guide_mode .fc-list-event-dot{
  border-color: #dee2e6;
}


.finish .fc-daygrid-event-dot {
  border-color: #198754;
}
.in_progress .fc-daygrid-event-dot {
  border-color: #ffc107;
}
.open .fc-daygrid-event-dot {
  border-color: #dee2e6;
}
.fc-daygrid-event-dot {
  margin: 0px 2px;
}
.fc-list-event{
  cursor: pointer;
}


.alert-danger {
  font-size: 13px;
  border-radius: 0px;
}


.rectangle.sold {
  background: green;
}
.fc .fc-daygrid-day-frame {
  overflow: hidden;
}
.fc .fc-button .fc-icon {
  font-size: 1.0em;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0.25em;
}
.fc .fc-button {
  line-height: 1.2;
  padding: 0.4em 0.5em;
}
.header {
  display: flex;
}
.header h2 {
  flex-grow: 1;
  margin: 20px 5px;
}
.header select {
  margin: 20px 5px;
}
.modal-container {
  padding: 20px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 0.9rem;
}

.fc-list-table .fc-daygrid-event-dot {
  display: none !important;
}

/*bootstrap fix*/
a {
 color: #212529;
 text-decoration: none;
}

.fc-day-past {
  background-color: #f5f5f5;
}
.fc-day-past a{
  color: #919193 !important;
}

.form-check-input:checked {
  background-color: #198754;
  border-color: #198754;
}
/*:root {*/
/*  --bs-btn-color: #fff;*/
/*  --bs-btn-bg: #375a7f;*/
/*  --bs-btn-border-color: #375a7f;*/
/*  --bs-btn-hover-color: #fff;*/
/*  --bs-btn-hover-bg: #2f4d6c;*/
/*  --bs-btn-hover-border-color: #2c4866;*/
/*  --bs-btn-focus-shadow-rgb: 85,115,146;*/
/*  --bs-btn-active-color: #fff;*/
/*  --bs-btn-active-bg: #2c4866;*/
/*  --bs-btn-active-border-color: #29445f;*/
/*  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);*/
/*  --bs-btn-disabled-color: #fff;*/
/*  --bs-btn-disabled-bg: #375a7f;*/
/*  --bs-btn-disabled-border-color: #375a7f;*/
/*}*/
.App {
  text-align: center;
}

.App a {
  /*color: #5c005c;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #919193;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
