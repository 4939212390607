.rsdt {
    box-sizing: border-box;
}

.rsdt *,
.rsdt *::before,
.rsdt *::after {
    box-sizing: inherit;
}

.rsdt.rsdt-container {
    overflow: auto;
}

.rsdt.rsdt-highlight {
    background-color: yellow;
}

.rsdt.rsdt-sortable {
    margin-left: 10px;
}

.rsdt.rsdt-sortable-icon::after {
    font-size: 20px;
    font-style: normal;
    content: '\21D5';
    cursor: pointer;
}

.rsdt.rsdt-sortable-asc::after {
    font-size: 20px;
    font-style: normal;
    content: '\2193';
    cursor: pointer;
}

.rsdt.rsdt-sortable-desc::after {
    font-size: 20px;
    font-style: normal;
    content: '\2191';
    cursor: pointer;
}

.audioguide {
    width: 6em;
}

/*sticky header*/
/*.fc-toolbar.fc-header-toolbar {*/
/*    position: sticky;*/
/*    top: 70px;*/
/*    z-index: 10;*/
/*    background: white*/
/*}*/

/*.fc-head-container.fc-widget-header {*/
/*    position: sticky;*/
/*    top: 208px;*/
/*    z-index: 10;*/
/*    background: white;*/
/*}*/
/*a {*/
/*    text-decoration: underline;*/
/*}*/
.link-underline-opacity-25 {
    --bs-link-underline-opacity: 0.25;
    --bs-link-opacity: 1;
    --bs-success-rgb: 25,135,84;
}

.link-offset-2 {
    text-underline-offset: 0.25em!important;
}
.link-success {
    color: RGBA(var(--bs-success-rgb),var(--bs-link-opacity,1))!important;
    -webkit-text-decoration-color: RGBA(var(--bs-success-rgb),var(--bs-link-underline-opacity,1))!important;
    text-decoration-color: RGBA(var(--bs-success-rgb),var(--bs-link-underline-opacity,1))!important;
}
